<template>
  <div class="lg:w-2/6 md:w-1/2 bg-white shadow-lg rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
    <h3 class='mb-6 text-purple-900 h3'>
      Log in
    </h3>
    <form  @submit.prevent="signIn">
      <!-- Error message -->
      <div
        v-if="errorMsg"
        class=" text-red-500"
      >
        <span class="text-sm">{{ errorMsg }}</span>
      </div>
      <!-- Input email -->
      <div class="relative mb-4">
        <input 
          v-model.trim="email"
          type="email"
          class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-lg outline-none  text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
          placeholder="Email" />
      </div>
      <!-- Input passwortd -->
      <div class="relative mb-4">
        <input 
          v-model.trim="password"
          type="password"
          class="w-full  bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200  outline-none text-lg text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
          placeholder="Password" />
      </div>
      <!-- Submit button -->
      <button 
        type="submit"
        class="w-full text-white border-0 py-2 px-8 focus:outline-none font-medium  rounded text-xl bg-blue-600"
      >Log in</button>
    </form>
    <p class="text-sm text-blue-500 py-5 text-center mb-3 mt-1">
      <router-link 
        to="/reset-password"
        class="no-underline border-b border-blue text-blue"
      >Forgotten password?</router-link>
    </p>
    <router-link 
      to="/signup"
      class="w-full text-white  border-0 py-2 px-8 focus:outline-none font-medium  rounded text-xl bg-green-500"
    >Create New Account</router-link>
    <!-- <button class="text-white  border-0 py-2 px-8 focus:outline-none font-medium  rounded text-xl bg-green-500 " >Create New Account</button> -->
  </div>
</template>

<script>
import firebaseApp from '@/firebase-app'

export default {
  name: 'SigninView',
  data() {
    return {
      email: null,
      password: null,
      errorMsg: null
    }
  },
  methods: {
    async signIn() {
      this.errorMsg = null
      let result = await firebaseApp.signin(this.email, this.password)
      if(result.message) {
        console.log(result.code, result.message)
        this.errorMsg = await firebaseApp.getCustomErrorMessage(result.code)
      } else {
        this.$router.push('/dashboard')
      }
    }
  },
  // async created() {
  //   let claims = await firebaseApp.getUserClaims()
  //   console.log(claims);
  // },
}
</script>